import styled from 'styled-components';
import {
  Box,
  Select as MUISelect,
  MenuItem as MUIMenuItem,
  FormControl as MUIFormControl,
  SelectProps,
  Typography,
} from '@material-ui/core';

export const Select = styled(MUISelect)<SelectProps>`
  ${({ theme: { colors, palette, typography } }) => `
    width: 100%;
    height: 48px;
    font-weight: 500;
    font-size: ${typography.body1.fontSize};
    line-height: ${typography.body1.lineHeight};
    color: rgba(85, 85, 85, 1);
    background-color: ${colors.greys.greys3};

    &::after {
      content: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid transparent;
      transition: 150ms cubic-bezier(0.4,0,0.2,1);
    }

    &.MuiInput-underline {
      &:hover,
      &.Mui-focused {
        &:not(.Mui-disabled) {
          &::before {
            border-bottom: 1px;
            border: 1px solid ${palette.primary.main};
          }
        }
      }

      &.Mui-error {
        background-color: ${colors.reds.reds1};

        &:not(.Mui-disabled) {
          &::before {
            border-color: ${colors.reds.reds0};
          }
        }
      }
    }

    .MuiSelect-select {
      padding: 11px 40px 11px 20px;

      &:focus {
        background-color: ${colors.greys.greys3};
      }
    }

    .MuiSelect-nativeInput {
      height: 100%;
      border: 0;
      padding: 0 15px;
      font-weight: 500;
      font-size: ${typography.body1.fontSize};
      line-height: ${typography.body1.lineHeight};
      font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif;
      color: ${palette.text.greys25};
      background-color: transparent;

      &[value=""] {
        opacity: 1;
      }
    }
    
    .MuiSelect-selectMenu {
      color: ${colors.greys.greys25};
      font-size: ${typography.body1.fontSize};
      line-height: ${typography.body1.lineHeight};
      font-weight: 500;
    }
  `}
`;

export const MenuItem = styled(MUIMenuItem)`
  ${({ theme: { palette } }) => `

    .MuiTypography-root {
      transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      border-color: red;
    }

    &.MuiMenuItem-root {
      padding: 10px;
    }

    &.MuiListItem-button {
      transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
      background-color: transparent;

      &:hover {
        & > .MuiTypography-root,
        .MuiTypography-root:first-child {
          color: ${palette.action.hover};
          background-color: transparent;
        }
      }
    }

    &.Mui-selected {
      background-color: transparent;

      .MuiTypography-root,
      .MuiTypography-root:first-child {
        color: ${palette.secondary.main};
      }

      &:hover {
        background-color: transparent;

        .MuiTypography-root,
        .MuiTypography-root:first-child {
          color: ${palette.secondary.main};
        }
      }
    }
  `};
`;

export const Icon = styled(Box)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const Label = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    opacity: 0.75;
  `}
`;

export const ErrorMessage = styled(Typography)`
  ${({ theme: { palette, spacing } }) => `
    margin-top: ${spacing(1)}px;
    color: ${palette.error.main};
  `}
`;

export const FormControl = styled(MUIFormControl)`
  width: 100%;
`;

export const Name = styled(Typography)`
  font-weight: 500;
`;
