import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export const Field = styled(TextField)`
  ${({
    theme: {
      colors,
      palette,
      spacing,
      typography,
      transitions: { duration, easing },
    },
  }) => `

    .MuiInputBase-root {
      border-radius: 0;
      border: 1px solid transparent;
      background-color: ${colors.greys.greys3};
      transition: ${duration.short}ms ${easing.easeInOut};

      &::before,
      &::after {
        content: none;
      }

      &:hover {
        border-color: ${palette.primary.main};
      }

      &.Mui-focused {
        border-width: 1px;
        border-color: ${palette.primary.main};
      }

      &.Mui-error {
        background-color: ${colors.reds.reds1};
        border-color: ${colors.reds.reds0};

        &:hover {
          border-color: ${colors.reds.reds0};
        }
      }

      &.MuiInputBase-adornedEnd {
        padding-right: ${spacing(0.5)}px;
      }

      .Mui-disabled {
        cursor: not-allowed;
        opacity: 0.6;
        border-color: transparent;
      }
    }

    .MuiInputBase-multiline {
      padding: 11px 18px;
    }

    .MuiInputBase-input {
      height: ${spacing(4)}px;
      color: ${palette.text.primary};
      -webkit-text-fill-color: ${palette.text.primary};
      line-height: ${spacing(2.25)}px;
      padding: 8px 20px;
      font-style: normal;
      font-weight: 500;
      font-size: ${typography.body1.fontSize};
      opacity: 1;

      &::placeholder {
        font-style: normal;
        font-weight: 500;
        color: ${palette.text.greys25};
        -webkit-text-fill-color: ${colors.greys.greys25};
        opacity: 1;
      }

      &.MuiInputBase-inputMultiline {
        padding: 0;
        height: 225px !important;
      }
    }
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.colors.greys.greys25};
  `}
`;

export const ErrorMessage = styled(Typography)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(1)}px;
    color: ${theme.palette.error.main};
  `}
`;
