import React, { FC, memo } from 'react';
import { Input, MenuProps } from '@material-ui/core';

import {
  Select as UISelect,
  MenuItem,
  Icon,
  Label,
  ErrorMessage,
  FormControl,
  Name,
} from './select.styles';
import { SelectProps, SelectOption } from './select.types';

const MENU_PROPS: Partial<MenuProps> = {
  PaperProps: {
    style: {
      boxShadow:
        '0px 4px 4px rgba(32, 59, 92, 0.07), 0px 7px 20px rgba(32, 59, 92, 0.1)',
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

const IconComponent: FC = () => (
  <Icon>
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71184 4.48528L5.22838 10.4557L0.74128 4.48528H9.71184Z"
        fill="#EC6779"
      />
    </svg>
  </Icon>
);

type RenderMenuItem<T> = (el: T, index: number, list: T[]) => JSX.Element;

const renderItem: RenderMenuItem<SelectOption> = ({ value, label }) => (
  <MenuItem key={value} value={value}>
    <Name variant="body1">{label}</Name>
  </MenuItem>
);

//TODO: create a custom Input component
// TODO: fix any
export const Select: FC<SelectProps<any>> = memo(
  ({
    label,
    data,
    helperText,
    error,
    renderMenuItem = renderItem,
    InputProps,
    className,
    ...selectProps
  }) => {
    const isVisibleErrorMessage = error && !!helperText;

    return (
      <FormControl error={!!error} className={className}>
        <Label variant="body2">{label}</Label>
        <UISelect
          {...selectProps}
          defaultValue=""
          IconComponent={() => <IconComponent />}
          input={<Input placeholder="Select" {...InputProps} />}
          MenuProps={MENU_PROPS}
        >
          {data.map(renderMenuItem)}
        </UISelect>
        {isVisibleErrorMessage && (
          <ErrorMessage variant="body2">{helperText}</ErrorMessage>
        )}
      </FormControl>
    );
  }
);
