import styled from 'styled-components';
import MUICheckbox from '@material-ui/core/Checkbox';
import MUIFormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import { WithMyTheme } from '@keaze/web/theme';

interface FormControlLabel extends WithMyTheme {
  $error?: boolean;
}

export const Checkbox = styled(MUICheckbox)`
  padding: 8px;
`;

export const FormControlLabel = styled(MUIFormControlLabel)<
  FormControlLabelProps & FormControlLabel
>`
  ${({
    theme: {
      palette,
      colors,
      typography: { body2 },
    },
    $error,
  }) => `
    color: ${colors.greys.greys6};
    margin: 0;
    width: 100%;
    align-items: flex-start;

    .MuiCheckbox-root {
      margin-left: -9px;
    }

    svg {
      stroke: ${$error ? palette.secondary.main : colors.greys.greys4};
      background-color: ${$error ? palette.error.light : 'transparent'};
      transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .MuiFormControlLabel-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      color: ${palette.text.primary};
      font-size: ${body2.fontSize};
      line-height: ${body2.lineHeight};
      font-weight: 500;
      margin: 6px 0 0 10px;
      transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);

      & > * {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }

    .Mui-checked {
      & + .MuiFormControlLabel-label {
        color: ${palette.text.primary};

        & > * {
          color: ${palette.text.primary};
          opacity: 1;
        }
      }

      svg {
        stroke: transparent;
        fill: ${palette.text.primary};
      }
    }

    &:hover {
      .MuiFormControlLabel-label {
        color: ${palette.action.hover};

        & > *:first-child {
          color: ${palette.action.hover};

          & > * {
            color: ${palette.action.hover};
          }
        }
      }

      svg {
        stroke: ${$error ? palette.secondary.main : palette.action.hover};
      }

      .Mui-checked {
        svg {
          stroke: transparent;
          fill: ${palette.action.hover};
        }
      }
    }


    &.Mui-disabled {
      cursor: not-allowed;

      .MuiFormControlLabel-label {
        color: ${colors.greys.greys4};

        & > *,
        & > *:first-child {
          color: ${colors.greys.greys4};
        }
      }

      svg {
        stroke: ${colors.greys.greys4};
      }
    }
  `}
`;

export const ErrorMessage = styled(Typography)`
  ${({ theme: { palette } }) => `
    color: ${palette.error.main};
    margin-left: 37px;
  `}
`;
