import React, { FC, memo, useMemo } from 'react';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';

import { Label, Field, ErrorMessage } from './textField.styles';
import { TextFieldProps } from './textField.types';

export const TextField: FC<TextFieldProps> = memo((props) => {
  const {
    label,
    helperText,
    fullWidth = true,
    formControlRef,
    type,
    ...restProps
  } = props;

  const inputProps: InputBaseComponentProps | undefined = useMemo(() => {
    if (type === 'number' || type === 'tel') {
      return {
        inputMode: type === 'number' ? 'numeric' : 'tel',
        pattern: '[0-9]*',
      };
    }

    return undefined;
  }, [type]);

  return (
    <FormControl fullWidth ref={formControlRef}>
      <Label variant="body2">{label}</Label>
      <Field
        fullWidth={fullWidth}
        type={type}
        variant="filled"
        inputProps={inputProps}
        hiddenLabel
        {...restProps}
      />
      {restProps.error && helperText && (
        <ErrorMessage variant="body2">{helperText}</ErrorMessage>
      )}
    </FormControl>
  );
});
