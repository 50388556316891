import React, { FC, memo, useEffect, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';

import {
  Checkbox as UICheckbox,
  FormControlLabel,
  ErrorMessage,
} from './checkbox.styles';
import { CheckboxProps } from './checkbox.types';

const CheckedIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2H16V16H2L2 2ZM0 2C0 0.89543 0.895431 0 2 0H16C17.1046 0 18 0.895431 18 2V16C18 17.1046 17.1046 18 16 18H2C0.89543 18 0 17.1046 0 16V2ZM14.2682 6.64018C14.6218 6.2159 14.5645 5.58534 14.1402 5.23177C13.7159 4.87821 13.0853 4.93553 12.7318 5.35981L8.4328 10.5186L6.20711 8.29289C5.81658 7.90236 5.18342 7.90236 4.79289 8.29289C4.40237 8.68341 4.40237 9.31658 4.79289 9.7071L7.79289 12.7071C7.99156 12.9058 8.26464 13.0117 8.54531 12.999C8.82599 12.9862 9.08836 12.856 9.26822 12.6402L14.2682 6.64018Z"
    />
  </svg>
);

const CheckboxIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="16" height="16" rx="1" strokeWidth="2" />
  </svg>
);

export const Checkbox: FC<CheckboxProps> = memo(
  ({
    label,
    disabled,
    error,
    helperText,
    icon = <CheckboxIcon />,
    checkedIcon = <CheckedIcon />,
    getRef,
    className,
    ...checkboxProps
  }) => {
    const checkboxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (getRef && checkboxRef.current) {
        getRef(checkboxProps.name, checkboxRef.current);
      }
    }, [checkboxRef.current]);

    const isError = !!error;
    const isVisibleErrorMessage = error && !!helperText;

    return (
      <FormControl ref={checkboxRef} error={isError}>
        <FormControlLabel
          $error={isError}
          className={className}
          label={label}
          disabled={disabled}
          control={
            <UICheckbox
              icon={icon}
              checkedIcon={checkedIcon}
              {...checkboxProps}
            />
          }
        />
        {isVisibleErrorMessage && (
          <ErrorMessage variant="body2">{helperText}</ErrorMessage>
        )}
      </FormControl>
    );
  }
);
